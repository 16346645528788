import { media, color } from "@sencrop/ui-components";
import { WebTitle, WebText } from "@sencrop/ui-web";
import { graphql } from "gatsby";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import styled from "styled-components";
import ImageElement from "../components/ImageElement";
import VideoElement from "../components/VideoElement";
import { blockCss, containerCss, contentCss } from "../helpers/style";
import BackgroundImage from "../images/testimonials/testimonials-graphic.png";
import QuoteStart from "../images/testimonials/quote-start.svg";

const SectionTestimonials = ({
  title,
  testimonials,
}: GatsbyTypes.SectionTestimonialsFragmentFragment) => {
  if (!testimonials) return null;

  return (
    <BackgroundWrapper>
      <Background>
        <Wrapper>
          <BackgroundImageContainer />
          <Slides>
            <Swiper
              modules={[Navigation, Autoplay]}
              centeredSlides={true}
              slidesPerView={1}
              spaceBetween={150}
              grabCursor={true}
              autoplay={{ delay: 4000 }}
              effect="fade"
              speed={2000}
            >
              {testimonials.map((content: any, index: number) => (
                <SwiperSlide
                  key={`testimonial${index}`}
                  style={{ minWidth: "100%" }}
                >
                  <SlideContainer>
                    <SlideImage>
                      {content?.photo && content?.videoUrl && (
                        <VideoElement
                          gatsbyImageData={content?.photo.gatsbyImageData}
                          alt={content?.photo?.title || ""}
                          videoUrl={content?.videoUrl}
                        />
                      )}
                      {content?.photo && !content?.videoUrl && (
                        <ImageElement
                          gatsbyImageData={content?.photo.gatsbyImageData}
                          alt={content?.photo?.title || ""}
                        />
                      )}
                    </SlideImage>
                    <TestimonialContent>
                      <div>
                        <WebTitle as="h2" size="s" color="branding-variant">
                          {title}
                        </WebTitle>
                        {content?.quotePart1?.childMarkdownRemark?.html && (
                          <WebTitle
                            as="div"
                            sizes={["l", "xl", "xl", "xl"]}
                            color="primary"
                            dangerouslySetInnerHTML={{
                              __html:
                                content.quotePart1.childMarkdownRemark.html,
                            }}
                            style={{
                              fontWeight: "800",
                              whiteSpace: "pre-wrap",
                            }}
                          />
                        )}
                        {content?.quotePart2?.childMarkdownRemark?.html && (
                          <WebText
                            dangerouslySetInnerHTML={{
                              __html:
                                content.quotePart2.childMarkdownRemark.html,
                            }}
                            style={{ whiteSpace: "pre-wrap" }}
                          />
                        )}
                      </div>
                      <div>
                        {content?.name && (
                          <WebTitle
                            color="branding-variant"
                            style={{ fontWeight: "bold" }}
                          >
                            {content.name}
                          </WebTitle>
                        )}
                        {content?.title && (
                          <WebText color="branding-variant">
                            {content.title}
                          </WebText>
                        )}
                      </div>
                      <QuoteStartContainer>
                        <img src={QuoteStart} alt="Quote start" />
                      </QuoteStartContainer>
                      <QuoteStopContainer>
                        <img src={QuoteStart} alt="Quote stop" />
                      </QuoteStopContainer>
                    </TestimonialContent>
                  </SlideContainer>
                </SwiperSlide>
              ))}
            </Swiper>
          </Slides>
        </Wrapper>
      </Background>
    </BackgroundWrapper>
  );
};

export default SectionTestimonials;

export const fragment = graphql`
  fragment SectionTestimonialsFragment on ContentfulSectionTestimonials {
    title
    testimonials {
      photo {
        gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
        title
      }
      title
      name
      quotePart1 {
        childMarkdownRemark {
          html
        }
      }
      quotePart2 {
        childMarkdownRemark {
          html
        }
      }
      videoUrl
    }
  }
`;

const BackgroundWrapper = styled.div`
  padding: 2.5rem 0 2rem 0;
  ${media.greaterThan("tablet")`
    padding: 3.5rem 0;
  `}
`;

const Background = styled.div`
  background-color: ${color("background-primary")};
`;

const Wrapper = styled.div`
  ${containerCss}
  ${contentCss}
  ${blockCss}
  position: relative;
  width: 100%;
  padding: 0 0;
  flex: 1;
  ${media.greaterThan("tablet")`
    padding: 0 0;
  `}
  .swiper-wrapper {
    display: flex;
  }
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  top: -2rem;
  left: -9rem;
  width: 18rem;
  height: 18rem;
  background-image: url(${BackgroundImage});
  background-size: contain;
  ${media.greaterThan("tablet")`
    left: -10rem;
    top: -2rem;
    width: 22.5rem;
    height: 22.5rem;
  `}
`;

const SlideContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 1.5rem;
  padding: 2.5rem 1rem 4.25rem 1rem;
  ${media.greaterThan("tablet")`
    padding: 7.25rem 2rem;
  `}
  ${media.greaterThan("tablet")`
    grid-template-columns: 468px 1fr;
`}
`;

const TestimonialContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div:first-child {
    margin-bottom: 1.5rem;
    > * {
      margin-bottom: 0.5rem;
    }
  }
  text-align: center;
  ${media.greaterThan("tablet")`
    text-align: left;
    div:first-child {
      margin-bottom: 0;
    }
`}
`;

const QuoteStartContainer = styled.div`
  position: absolute;
  img {
    height: 4.5rem;
  }
  top: -1rem;
  left: -2rem;
  ${media.greaterThan("tablet")`
      img {
        height: 7rem;
      }
      top: -5.4rem;
      left: -2rem;
    `}
`;

const QuoteStopContainer = styled.div`
  position: absolute;
  img {
    height: 4.5rem;
  }
  bottom: 1rem;
  right: -2rem;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  ${media.greaterThan("tablet")`
      img {
        height: 7rem;
      }
      bottom: -3rem;
      right: -2rem;
    `}
`;

const Slides = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  &:before {
    display: block;
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 200px;
    left: 0;
    ${media.greaterThan("tablet")`
      border-radius: 6px;
      top: 40px;
      bottom: 0;
    `}
  }
`;

const SlideImage = styled.div`
  img {
    border-radius: 6px;
  }
  ${media.greaterThan("tablet")`
    -webkit-filter: drop-shadow(0px 12px 8px rgba(0, 0, 0, 0.3));   
    filter: drop-shadow(0px 12px 8px rgba(0, 0, 0, 0.3)); 
  `}
`;
